import { observer } from "mobx-react";
import { Form, ListGroup, ListGroupItem, Nav } from "react-bootstrap";
import { useState } from "react";
import { BsChevronContract, BsChevronExpand } from "react-icons/bs";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { PodcastListItem } from "./PodcastListItem";
import styles from "./styles/PodcastList.module.css";

interface PodcastListProps {
    podcasts: Podcast[];
    limit?: number;
    flush?: boolean;
    defaultSort?: string;
}

export const PodcastList = observer(({ podcasts = [], limit = -1, flush = false, defaultSort }: PodcastListProps) => {
    const [isExpand, setIsExpand] = useState(false);
    const [filter, setFilter] = useState("");

    const [order, setOrder] = useState<boolean>(true);
    const [sort, setSort] = useState<string>(defaultSort || "name");

    const sortIcon = order ? <AiOutlineArrowDown /> : <AiOutlineArrowUp />;

    const variant = flush ? "flush" : undefined;

    const pods: Podcast[] = [...podcasts];

    const sortPodcastByNameDesc = (a: Podcast, b: Podcast) => {
        return a.name.localeCompare(b.name);
    };

    const sortPodcastByNameAsc = (a: Podcast, b: Podcast) => {
        return b.name.localeCompare(a.name);
    };

    const sortPodcastByDateDesc = (a: Podcast, b: Podcast) => {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    };

    const sortPodcastByDateAsc = (a: Podcast, b: Podcast) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    };

    const sortPodcastByAuthorDesc = (a: Podcast, b: Podcast) => {
        return a.author.name.localeCompare(b.author.name);
    };

    const sortPodcastByAuthorAsc = (a: Podcast, b: Podcast) => {
        return b.author.name.localeCompare(a.author.name);
    };

    const sortPodcastByEpisodeCountDesc = (a: Podcast, b: Podcast) => {
        if (!a.episodes_count || !b.episodes_count) {
            return 0;
        }
        return a.episodes_count - b.episodes_count;
    };

    const sortPodcastByEpisodeCountAsc = (a: Podcast, b: Podcast) => {
        if (!a.episodes_count || !b.episodes_count) {
            return 0;
        }
        return b.episodes_count - a.episodes_count;
    };

    const onSort = (sort: string) => {
        setSort(sort);
        setOrder(!order);
    };

    const sorting: SortCollection = {
        name: {
            sort: order ? sortPodcastByNameDesc : sortPodcastByNameAsc,
            label: "Namn",
        },
        date: {
            sort: order ? sortPodcastByDateAsc : sortPodcastByDateDesc,
            label: "Tillagd",
        },
        author: {
            sort: order ? sortPodcastByAuthorDesc : sortPodcastByAuthorAsc,
            label: "Utgivare",
            prop: "author",
        },
        episodes: {
            sort: order ? sortPodcastByEpisodeCountDesc : sortPodcastByEpisodeCountAsc,
            label: "Episoder",
            prop: "episodes_count",
        },
    };

    if (sorting.hasOwnProperty(sort)) {
        pods.sort(sorting[sort].sort);
    }

    const showExpand = limit > 0 && podcasts.length > limit + 10 && filter === "";
    return (
        <ListGroup variant={variant}>
            {podcasts.length > 10 && (
                <ListGroupItem>
                    <div key={"filter-podcast-list"} className={styles.filter}>
                        <Form.Control
                            onChange={(event) => {
                                setFilter(event.target.value);
                            }}
                            value={filter}
                            type="search"
                            placeholder="Sök i listan..."
                        />
                    </div>
                </ListGroupItem>
            )}
            {podcasts.length > 4 && (
                <ListGroupItem>
                    <div className={styles.sort}>
                        <Nav activeKey={sort}>
                            {Object.keys(sorting).map((key) => {
                                const currentSort = sorting[key];
                                const shouldShow =
                                    currentSort.prop && pods.length > 0
                                        ? pods[0].hasOwnProperty(currentSort.prop)
                                        : true;
                                return (
                                    shouldShow && (
                                        <Nav.Item key={key}>
                                            <Nav.Link
                                                className={sort === key ? styles.active : ""}
                                                onClick={() => {
                                                    onSort(key);
                                                }}>
                                                {sorting[key].label} {sort === key ? sortIcon : null}
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                );
                            })}
                        </Nav>
                    </div>
                </ListGroupItem>
            )}
            {pods.map((pod: Podcast, index: number) => {
                if (limit > 0 && index > limit && showExpand && !isExpand) {
                    return null;
                }

                if (filter.length > 0) {
                    if (pod.name.toLowerCase().indexOf(filter.toLowerCase()) === -1) {
                        return null;
                    }
                }

                return <PodcastListItem key={"podcast-list-item-" + index} podcast={pod} />;
            })}
            {showExpand && (
                <a
                    href={"#expand"}
                    onClick={(event) => {
                        event.preventDefault();
                        setIsExpand(!isExpand);
                    }}
                    className={"list-group-item d-flex align-items-center " + styles.expand}>
                    {isExpand ? (
                        <>
                            <BsChevronContract /> Dölj
                        </>
                    ) : (
                        <>
                            <BsChevronExpand /> Visa alla {podcasts.length} poddar...
                        </>
                    )}
                </a>
            )}
        </ListGroup>
    );
});
