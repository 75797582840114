import { TinyPodcasListItem } from "./TinyPodcasListItem";

export function PopularPodcasts({ podcasts }: { podcasts: Podcast[] | undefined }) {
    if (!podcasts) {
        return null;
    }
    return (
        <div>
            <div>
                {podcasts.map((podcast) => {
                    return <TinyPodcasListItem key={`popular-podcast-${podcast.id}`} podcast={podcast} />;
                })}
            </div>
        </div>
    );
}
