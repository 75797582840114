import { Link } from "react-router-dom";
import styles from "./styles/TinyPodcasListItem.module.css";
import { getPodcastImage, ImageSizes } from "@/core/helpers";

export function TinyEpisodeListItem({ podcast, episode }: { podcast: Podcast; episode: Episode }) {
    const podcastImage = getPodcastImage(podcast, ImageSizes.Thumb);
    return (
        <div className={styles.item}>
            <img src={podcastImage} alt={podcast.name} />
            <div className={styles.content}>
                <h5>
                    <Link to={`/podd/${podcast.slug}/${episode.slug}`}>{episode.title}</Link>
                </h5>
                <p>{podcast.name}</p>
            </div>
        </div>
    );
}
