import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createClient } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";
import { CategoryMap } from "@/core/autolinking";

type GroupedCategory = {
    category: Category;
    count: number;
};

export class CategoryStore {
    categories: Category[] = [];
    activeCategory: Category | null = null;
    podcasts: Podcast[] = [];
    private client: ApiClient;
    latest: Episode[] = [];
    loading = false;
    page = 0;
    toplist: Podcast[] = [];

    constructor() {
        makeAutoObservable(this);
        makePersistable(
            this,
            {
                name: "CategoryStore",
                properties: ["categories"],
                storage: window.localStorage,
                expireIn: 1000 * 60 * 60 * 12, // 12 hours
                removeOnExpiration: true,
            },
            {
                delay: 200,
                fireImmediately: false,
            }
        );
        this.client = createClient();
        this.preload();
    }

    async getCategories() {
        // Cache this in sessionStorage
        const cats = sessionStorage.getItem("hp_categories");
        if (cats) {
            this.categories = JSON.parse(cats);
            return;
        }

        const { categories } = await this.client.get<CategoryListResponse>("/categories");

        const filteredCategories = categories
            .filter((c: Category) => c.name.toLowerCase() !== "podcaster")
            .filter((c: Category) => {
                return c.podcasts_count && c.podcasts_count > 0;
            })
            .sort((a: Category, b: Category) => {
                return (b.podcasts_count || 0) - (a.podcasts_count || 0);
            });

        sessionStorage.setItem("hp_categories", JSON.stringify(filteredCategories));
        return filteredCategories;
    }

    async loadAndFindBySlug(slug: string) {
        const { categories } = await this.client.get<CategoryListResponse>("/categories");
        const c = categories.filter((c: Category) => {
            return c.slug === slug;
        });
        return c.length > 0 ? c[0] : null;
    }

    async bySlug(slug: string) {
        this.activeCategory = null;
        let category: null | Category;
        if (this.categories.length > 0) {
            // FIND
            const c = this.categories.filter((c: Category) => {
                return c.slug === slug;
            });

            if (c.length > 0) {
                category = c[0];
            } else {
                category = await this.loadAndFindBySlug(slug);
            }
        } else {
            category = await this.loadAndFindBySlug(slug);
        }
        if (category) {
            const response = await this.client.get<SingleCategoryResponse>("/categories/" + category.id);
            this.activeCategory = response.category;
            this.podcasts = response.category.podcasts || [];
        }
    }

    async loadLatest() {
        if (!this.activeCategory) {
            return;
        }
        const response = await this.client.get<EpisodesResponse>(`/categories/${this.activeCategory.id}/episodes`);
        this.latest = response.episodes;
        this.page = 1;
    }

    async loadMore() {
        // chcek for active category
        if (!this.activeCategory) {
            return;
        }

        this.loading = true;
        this.page += 1;
        const response = await this.client.get<EpisodesResponse>(
            `/categories/${this.activeCategory.id}/episodes?page=${this.page}`
        );
        const old = this.latest;
        this.latest = [...old, ...response.episodes];
        this.loading = false;
    }

    async loadToplist() {
        if (!this.activeCategory) {
            return;
        }
        const response = await this.client.get<CategoryToplistResponse>(
            `/categories/${this.activeCategory.id}/toplist`
        );
        this.toplist = response.entries
            .map((e: ToplistEntry) => {
                return e.podcast;
            })
            .filter((p: Podcast | undefined) => p !== undefined) as Podcast[];
    }

    get similarCategories(): Category[] {
        const grouped: GroupedCategory[] = [];
        this.podcasts.forEach((p: Podcast) => {
            p.categories.forEach((c: Category) => {
                if (!this.activeCategory) {
                    return;
                }
                const invalidCategory = c.slug === this.activeCategory.slug || c.slug === "podcaster";

                if (!invalidCategory) {
                    const found = grouped.find((g: GroupedCategory) => {
                        return g.category.id === c.id;
                    });

                    if (found) {
                        found.count += 1;
                    } else {
                        grouped.push({
                            category: c,
                            count: 1,
                        });
                    }
                }
            });
        });

        grouped.sort((a: GroupedCategory, b: GroupedCategory) => {
            return a.count > b.count ? -1 : 1;
        });

        return grouped.map((g: GroupedCategory) => g.category).splice(0, 15);
    }

    sortBy(sort: string) {
        const cats = [...this.categories];
        switch (sort) {
            case "name":
                cats.sort((a: Category, b: Category) => {
                    return a.name > b.name ? 1 : -1;
                });
                break;
            case "count":
                cats.sort((a: Category, b: Category) => {
                    if (a.podcasts_count && b.podcasts_count) {
                        return a.podcasts_count > b.podcasts_count ? -1 : 1;
                    }
                    return 0;
                });
                break;
        }
        this.categories = cats;
    }

    // We want to make sure categories are loaded as soon as possible without blocking the UI and not hit our performance too much
    // So we load them in the background and cache them in localstorage
    async preload() {
        const cached = localStorage.getItem("hp_categories");
        if (cached) {
            this.categories = JSON.parse(cached);
        }
        await this.getCategories();
    }

    get popular(): Category[] {
        const popularSlugs = ["komedi", "samhalle-och-kultur", "verkliga-brott", "historia", "nyheter"];

        return this.categories.filter((c: Category) => {
            return popularSlugs.includes(c.slug);
        });
    }

    // Generate a CategoryMap for autolinking
    get categoryMap(): CategoryMap {
        const map: CategoryMap = {};
        this.categories.forEach((c: Category) => {
            // remove non word characters from c.name
            c.name = c.name.replace(/[^a-zA-ZåäöÅÄÖ0-9 ]/g, "");
            map[c.name] = `/kategori/${c.slug}`;
        });
        return map;
    }
}
