import { IoMdShare } from "react-icons/io";
import { ActionButton } from "./ActionButton";

interface ShareButtonProps {
    episode?: Episode | null;
    podcast: Podcast;
}

export const ShareButton = ({ episode = null, podcast }: ShareButtonProps) => {
    const canShare = "canShare" in navigator;
    const share = () => {
        const url = episode
            ? `https://hittapodd.se/podd/${podcast.slug}/${episode.slug}`
            : `https://hittapodd.se/podd/${podcast.slug}`;
        const shareData = {
            url,
            title: episode ? `${episode.title} - ${podcast.name}` : `Podcast - ${podcast.name}`,
            text: episode
                ? `Lyssna på ${episode.title} från ${podcast.name} hos HittaPodd.se`
                : `Alla avsnitt från ${podcast.name} hos HittaPodd.se`,
        };

        if (canShare) {
            navigator
                .share(shareData)
                .then(() => console.log("Shared it"))
                .catch((error) => console.log("Error sharing", error));
        } else {
            console.log("Your client do not support sharing");
        }
    };
    return canShare ? (
        <ActionButton variant={"secondary"} icon={<IoMdShare fontSize={"inherit"} />} label={"Dela"} onClick={share} />
    ) : null;
};
