import { TinyEpisodeListItem } from "./TinyEpisodeListItem";

export function PopularEpisodes({ episodes }: { episodes: Episode[] | undefined }) {
    if (!episodes) {
        return null;
    }
    return (
        <div>
            <div>
                {episodes.map((episode) => {
                    return episode.podcast ? (
                        <TinyEpisodeListItem
                            key={`popular-episode-${episode.id}`}
                            episode={episode}
                            podcast={episode.podcast}
                        />
                    ) : null;
                })}
            </div>
        </div>
    );
}
