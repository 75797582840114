export const Urls = {
    index: () => "/",
    podcastHome: (podcastSlug: string) => `/podd/${podcastSlug}`,
    podcastEpisode: (podcastSlug: string, episodeSlug: string) => `/podd/${podcastSlug}/${episodeSlug}`,
    podcastSeason: (podcastSlug: string, seasonSlug: string) => `/podd/${podcastSlug}/season/${seasonSlug}`,
    categories: () => "/kategori",
    category: (categorySlug: string) => `/kategori/${categorySlug}`,
    author: (authorSlug: string) => `/upphovsman/${authorSlug}`,
    collections: () => "/samling",
    collection: (collectionSlug: string) => `/samling/${collectionSlug}`,
    focusAreas: () => "/fokus",
    focusArea: (focusAreaSlug: string) => `/fokus/${focusAreaSlug}`,
    about: () => "/om",
    policy: () => "/policy",
    search: () => "/hitta",
    englishRoot: () => "/en",
    allPodcasts: () => "/poddar",
    toplist: () => "/topplista",
    toplistDate: (date: string) => `/topplista/${date}`,
    account: () => "/account",
};
