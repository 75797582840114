import { makeAutoObservable } from "mobx";
import { createClient } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";

export class SearchStore {
    result = {
        results: [],
    };
    podcasts: Podcast[] = [];
    episodes: Episode[] = [];
    searchResult: Episode[] = [];
    lastQuery = "";
    private client: ApiClient;

    constructor() {
        makeAutoObservable(this);
        this.client = createClient();
    }

    async getFilteredPodcasts(input: string, page = 1) {
        if (!input.trim()) {
            this.result.results = [];
        } else {
            this.result = await this.client.get(`/podcasts/filter/${input}?limit=4&page=${page}`);
        }
    }

    async searchPodcastsAndEpisodes(query: string) {
        this.podcasts = [];
        this.lastQuery = "";
        if (query.trim().length > 0) {
            this.lastQuery = query.trim();
            const { podcasts } = await this.client.get<PodcastsResponse>(
                `/podcasts?q=` + encodeURIComponent(query.trim())
            );
            this.podcasts = podcasts;

            const { episodes } = await this.client.get<EpisodesResponse>(
                `/episodes?q=` + encodeURIComponent(query.trim())
            );
            this.episodes = episodes;
        }
    }

    async searchEpisodesInPodcast(query: string, podcastId: number) {
        if (query.length > 0) {
            const { episodes } = await this.client.get<EpisodesResponse>(
                `/podcasts/${podcastId}/episodes?filter=${encodeURIComponent(query)}`
            );
            this.searchResult = episodes;
        } else {
            this.searchResult = [];
        }
    }
}
