import { makeAutoObservable } from "mobx";
import i18n from "../i18n";

export class AppStore {
    showSignup = false;
    constructor() {
        makeAutoObservable(this);
    }

    setShowSignup(show: boolean) {
        this.showSignup = show;
    }

    async changeLanguage(lang: string) {
        console.log("changeLanguage", lang);
        await i18n.changeLanguage(lang);
    }
}
