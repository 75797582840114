export class ApiClient {
    private readonly baseURL: string;
    private controller: AbortController;
    private cache: { [key: string]: unknown } = {};

    constructor(baseURL = "") {
        this.baseURL = baseURL;
        this.controller = new AbortController();

        setInterval(() => {
            this.cache = {};
        }, 60 * 1000);
    }

    private async fetch<T>(url: string, options: RequestInit = {}): Promise<T> {
        const startTime = Date.now();
        const newOptions: RequestInit = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            signal: this.controller.signal,
            ...options,
        };
        const response = await fetch(this.baseURL + url, newOptions);

        const endTime = Date.now();
        const time = endTime - startTime;
        if (time > 350) {
            console.warn(`Slow API Query - [${newOptions.method}] ${this.baseURL + url}; ${time}ms;`);
        }
        const json = await response.json();
        return json as T;
    }

    async get<T>(url: string): Promise<T> {
        if (this.cache.hasOwnProperty(url)) {
            return <T>this.cache[url];
        }
        const response = await this.fetch<T>(url);
        this.cache[url] = response;
        return response;
    }

    async post<T>(url: string, payload: unknown = {}): Promise<T> {
        return await this.fetch<T>(url, {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(payload),
        });
    }

    async beacon(url: string, payload: BodyInit | null | undefined) {
        navigator.sendBeacon(this.baseURL + url, payload);
        return true;
    }

    abort() {
        this.controller.abort();
    }
}
