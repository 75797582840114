import Pubsub from "pubsub-js";
import { initializeApp } from "firebase/app";
// eslint-disable-next-line import/named
import { getAuth, signOut, onAuthStateChanged, User } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCC4-h2wjEp6TTh2aR29fcMaHzCe5s94WA",
    authDomain: "hittapodd.firebaseapp.com",
    projectId: "hittapodd",
    storageBucket: "hittapodd.appspot.com",
    messagingSenderId: "242627370341",
    appId: "1:242627370341:web:a96e0ccd510e400240958c",
    measurementId: "G-3KGYEH26FY",
};

// Initialize Firebase

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

export const getUser = () => {
    return auth.currentUser;
};

export const getCurrentUserId = () => {
    const user = getUser();
    if (user) {
        return user.uid;
    }
    return null;
};

export const getCurrentUserDisplayName = () => {
    const user = getUser();
    if (user) {
        return user.displayName || "";
    }
    return "";
};

export const getCurrentUserImageUrl = () => {
    const user: User | null = getUser();
    if (user) {
        return user.photoURL || "";
    }
    return null;
};

onAuthStateChanged(auth, (user: User | null) => {
    if (user) {
        user.getIdToken(true).then((token) => {
            Pubsub.publish("user/login", {
                token,
                uid: user.uid,
                email: user.email,
                name: user.displayName,
            });
        });
    } else {
        console.log("User is signed out.");
    }
});

export const logout = async () => {
    await signOut(auth);
};
