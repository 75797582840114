import { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { PodcastList } from "./PodcastList";
import { ClampableText } from "./ClampableText";
import { useStores } from "@/hooks/useStores";

export const PodcastCollection = observer(({ slug }: PodcastCollectionProps) => {
    const { collectionStore } = useStores();

    const loadColl = useCallback(() => {
        collectionStore.loadCollection(slug).catch(console.error);
    }, [collectionStore, slug]);

    useEffect(() => {
        loadColl();
    }, [loadColl]);

    return collectionStore.loading ? (
        <div>Loading...</div>
    ) : (
        <Card>
            <Card.Body>
                <Card.Title>{collectionStore.collection?.name}</Card.Title>
                <ClampableText text={collectionStore.collection?.content} contractAt={130} />
            </Card.Body>

            {collectionStore.collection && <PodcastList flush podcasts={collectionStore.collection.podcasts} />}

            <Card.Body>
                <Link to={"/samling/" + collectionStore.collection?.slug}>Alla episoder</Link>
            </Card.Body>
        </Card>
    );
});
