import { makeAutoObservable, reaction, toJS } from "mobx";
import localforage from "localforage";

export interface PlaylistItem {
    played: boolean;
    progress: number;
    episode: Episode;
    podcast: Podcast;
}

export class PlaylistStore {
    filter = "";
    episodes: PlaylistItem[] = [];
    filtered: PlaylistItem[] = [];
    isOpen = false;

    constructor() {
        makeAutoObservable(this);
        localforage.getItem("hp_playlist").then((playlist: unknown) => {
            if (playlist && Array.isArray(playlist)) {
                this.episodes = playlist
                    .filter((item) => item !== null)
                    .map((item: PlaylistItem) => {
                        return {
                            played: item.played,
                            progress: item.progress,
                            episode: item.episode,
                            podcast: item.podcast,
                        };
                    });
            }
        });

        reaction(
            () => this.episodes.length,
            async () => {
                await this.syncPlaylist();
            }
        );
    }

    findEpisode(episode: Episode): number {
        return this.episodes.findIndex((item: PlaylistItem) => item.episode.id === episode.id);
    }

    inList(episode: Episode): boolean {
        return this.findEpisode(episode) !== -1;
    }

    async remove(episode: Episode) {
        const index = this.findEpisode(episode);
        if (index > -1) {
            this.episodes.splice(index, 1);
        }
    }

    async add(episode: Episode, podcast: Podcast) {
        this.episodes.push({
            played: false,
            episode,
            progress: 0,
            podcast,
        });
    }

    private async syncPlaylist() {
        // console.log(this.episodes.length);
        await localforage.setItem(
            "hp_playlist",
            this.episodes.map((i: PlaylistItem) => {
                return {
                    played: i.played,
                    progress: i.progress,
                    episode: toJS(i.episode),
                    podcast: toJS(i.podcast),
                };
            })
        );
    }

    toggleOpen() {
        this.isOpen = !this.isOpen;
    }

    close() {
        this.isOpen = false;
    }

    toggleSort() {
        this.episodes = this.episodes.reverse();
        this.filtered = this.filtered.reverse();
    }

    filterEpisodes(filterText: string) {
        if (filterText.length === 0) {
            this.filtered = this.episodes;
        }

        this.filtered = this.episodes.filter((item: PlaylistItem) => {
            return (
                item.episode.title.toLowerCase().includes(filterText.toLowerCase()) ||
                item.podcast.name.toLowerCase().includes(filterText.toLowerCase())
            );
        });
    }
}
