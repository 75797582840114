import { Link } from "react-router-dom";
import clsx from "clsx";
import styles from "./styles/Breadcrumbs.module.css";

interface BreadcrumbsItem {
    link?: string;
    label: string;
    active?: boolean;
}

interface BreadcrumbsProps {
    items: BreadcrumbsItem[];
}

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => (
    <div className={styles.wrap}>
        <ol className={styles.list} itemScope itemType="https://schema.org/BreadcrumbList">
            <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                <Link to={"/"} itemProp="item">
                    <span itemProp={"name"}>Hem</span>
                    <meta itemProp="position" content="1" />
                </Link>
            </li>
            {items.map((item: BreadcrumbsItem, index: number) => {
                const cls = clsx({
                    [styles.active]: item.active,
                });
                return (
                    <li
                        className={cls}
                        key={`bc-index-${index}`}
                        itemProp="itemListElement"
                        itemScope
                        itemType="https://schema.org/ListItem">
                        {item.link && (
                            <Link to={item.link} itemProp={"item"}>
                                <span itemProp={"name"}>{item.label}</span>
                            </Link>
                        )}
                        {!item.link && <span itemProp={"name"}>{item.label}</span>}
                        <meta itemProp="position" content={(index + 2).toString()} />
                    </li>
                );
            })}
        </ol>
    </div>
);
