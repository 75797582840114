import { makeAutoObservable } from "mobx";
import { createClient } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";

export class AuthorStore {
    private client: ApiClient;
    author: Author | null = null;
    podcasts: Podcast[] = [];
    constructor() {
        makeAutoObservable(this);
        this.client = createClient();
    }

    public async getAuthorBySlug(slug: string) {
        const { author } = await this.client.get<AuthorResponse>(`/authors/bySlug/${slug}`);
        this.author = author;
        this.podcasts = author.podcasts || [];
    }
}
