import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import { Button, Spinner } from "react-bootstrap";
import { BsGoogle } from "react-icons/bs";
import { useCallback, useRef } from "react";
import { useStores } from "@/hooks/useStores";
import { auth } from "@/core/firebase";

interface GoogleSignupProps {
    disabled?: boolean;
}

export const GoogleSignup = ({ disabled = false }: GoogleSignupProps) => {
    const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);
    const isDoingLookup = useRef<boolean>(false);
    const { appStore, userStore } = useStores();

    const doGoogleAuth = useCallback(async () => {
        userStore.setAuthing(true);
        await signInWithGoogle();
        userStore.setAuthing(false);
    }, [signInWithGoogle, userStore]);

    if (error) {
        return (
            <div>
                <p>Error: {error.message}</p>
            </div>
        );
    }

    if (user && !isDoingLookup.current) {
        isDoingLookup.current = true;
        appStore.setShowSignup(false);
        isDoingLookup.current = false;
        return null;
    }

    return !user ? (
        <Button disabled={disabled || error || loading || user} variant={"outline-primary"} onClick={doGoogleAuth}>
            {loading && <Spinner size={"sm"} animation={"border"} />}
            {!loading && (
                <>
                    <BsGoogle size={16} /> Logga in med Google
                </>
            )}
        </Button>
    ) : null;
};
