import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import styles from "./styles/SimilarPodcasts.module.css";
import { useStores } from "@/hooks/useStores";
import { getPodcastImage, ImageSizes } from "@/core/helpers";

interface SimilarPodcastProps {
    podcast: Podcast;
}

const PlaceholderImg = () => <div className={styles.placeholder}></div>;

export const SimilarPodcasts = observer(({ podcast }: SimilarPodcastProps) => {
    const { podcastStore } = useStores();

    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const loadSimilar = useCallback(async () => {
        if (podcastStore.loadingSimilar) return;
        setLoading(true);
        try {
            await podcastStore.loadSimilar(podcast);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setIsError(true);
        }
    }, [podcast, podcastStore]);

    useEffect(() => {
        (async () => {
            await loadSimilar();
        })();
    }, [loadSimilar]);

    useEffect(() => {
        return () => {
            podcastStore.clearSimilar();
        };
    }, [podcastStore]);

    return (
        <div className={styles.elm}>
            <h2>Liknande Poddar</h2>
            {isError && <div>Problem ladda liknande poddar</div>}
            {loading && (
                <div className={styles.podcasts}>
                    <PlaceholderImg />
                    <PlaceholderImg />
                    <PlaceholderImg />
                    <PlaceholderImg />
                    <PlaceholderImg />
                    <PlaceholderImg />
                </div>
            )}
            {podcastStore.similar.length > 0 && !loading && (
                <div className={styles.podcasts}>
                    {podcastStore.similar.map((pod: Podcast, index: number) => {
                        return (
                            <div key={`similar-pod-${index}`} className={styles.podcast}>
                                <Link title={pod.name} to={"/podd/" + pod.slug}>
                                    <img alt={pod.name} loading={"lazy"} src={getPodcastImage(pod, ImageSizes.Small)} />
                                </Link>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
});
