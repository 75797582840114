import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFound = (): JSX.Element => (
    <>
        <Helmet>
            <title>Sidan kunde inte hittas - 404 - HittaPodd.se</title>
        </Helmet>
        <div>
            <h4>Sidan kunde inte hittas</h4>
            <p>
                Prova att gå till <Link to={"/"}>startsidan</Link> eller <Link to={"/kontakt"}>kontakta oss</Link> om du
                tror att något är knas som vi borde ta en kika på.
            </p>
        </div>
    </>
);

export default NotFound;
