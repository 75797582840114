import { MdRemoveCircleOutline } from "react-icons/md";
import { observer } from "mobx-react";
import { ActionButton } from "./ActionButton";
import { useStores } from "@/hooks/useStores";

interface AddToPlaylistButtonProps {
    episode: Episode;
    podcast: Podcast;
}

export const RemoveFromPlaylistButton = observer(({ episode }: AddToPlaylistButtonProps) => {
    const { playlistStore } = useStores();
    return (
        <ActionButton
            style={{
                color: "#828282",
            }}
            onClick={async () => {
                await playlistStore.remove(episode);
            }}
            icon={<MdRemoveCircleOutline />}
            label={`Ta bort från spellista`}
            variant={"clean"}
        />
    );
});
