import { Form, Modal } from "react-bootstrap";
import { observer } from "mobx-react";
import { GoogleSignup } from "./auth/GoogleSignup";
import styles from "./styles/SignupModal.module.css";
import { useStores } from "@/hooks/useStores";

export const SignupModal = observer(() => {
    const { appStore, userStore } = useStores();

    return (
        <Modal
            show={appStore.showSignup}
            onHide={() => {
                appStore.setShowSignup(false);
            }}
            style={{
                zIndex: 10002,
            }}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Skapa konto</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <>
                    <p className={styles.text}>
                        Om du skapar ett konto kan du följa poddar, få personliga spellistor med dina favoritpoddar och
                        skapa egna spellistor som är antingen privata eller publika.
                    </p>
                    <div className="d-grid gap-2 mt-3 mb-3">
                        <GoogleSignup disabled={!userStore.gdprApproved} />
                    </div>
                    <Form.Check
                        checked={userStore.gdprApproved}
                        onChange={(event) => {
                            userStore.setGdprApproved(event.target.checked);
                        }}
                        className={styles.checkbox}
                        type={"checkbox"}
                        id={"gdpr-checkbox"}
                        label={"Jag godkänner HittaPodd.se:s Privacy Policy"}
                    />
                </>
            </Modal.Body>
        </Modal>
    );
});
