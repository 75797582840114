export type CategoryMap = { [word: string]: string };

export function linkCategories(text: string, categories: CategoryMap): string {
    Object.entries(categories).forEach(([word, url]) => {
        const escapedWord = escapeRegExp(word);
        const regex = new RegExp(`(?<=\\s|^|[.,!?:;])${escapedWord}(?=\\s|$|[.,!?:;])`, "i");
        text = text.replace(regex, (matchedWord) => {
            return `<a href="${url}">${matchedWord}</a>`;
        });
    });
    return text;
}

function escapeRegExp(str: string): string {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
