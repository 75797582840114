import styles from "./styles/SquarePlaceholder.module.css";

interface PlaceholderProps {
    size?: "small" | "medium" | "large";
}

export const SquarePlaceholder = ({ size = "medium" }: PlaceholderProps) => {
    return (
        <div className={styles.square}>
            <div className={styles.placeholder + " " + styles[size]}></div>
        </div>
    );
};
