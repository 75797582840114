import { BsDownload } from "react-icons/bs";
import { ActionButton } from "./ActionButton";
import { bytesToSize } from "@/core/helpers";

interface DownloadButtonProps {
    episode: Episode;
}

export const DownloadButton = ({ episode }: DownloadButtonProps) => {
    return (
        <ActionButton
            icon={<BsDownload />}
            label={`Hämta (${bytesToSize(episode.duration)})`}
            href={episode.sound}
            variant={"secondary"}
        />
    );
};
