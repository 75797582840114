import { useState } from "react";
import styles from "./styles/ClampableText.module.css";
import { truncate } from "@/core/helpers";
export function ClampableText(props: { text: string | undefined; contractAt?: number }) {
    const limit = props.contractAt || 150;
    const [expanded, setExpanded] = useState(false);
    if (!props.text) {
        return null;
    }

    if (!expanded && props.text.length > limit) {
        return (
            <div
                onClick={() => {
                    setExpanded(true);
                }}
                title={props.text}
                className={styles.collapsed}>
                {truncate(props.text, limit)} <strong>Läs mer</strong>
            </div>
        );
    }

    return (
        <div
            onClick={() => {
                setExpanded(false);
            }}
            className={styles.expanded}>
            {props.text}{" "}
        </div>
    );
}
