import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createClient } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";

export class CollectionStore {
    private client: ApiClient;
    collection: Collection | null = null;
    collections: Collection[] = [];
    episodes: Episode[] = [];
    loading = false;
    page = 1;
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: "CollectionStore",
            properties: ["collections"],
            storage: window.localStorage,
            expireIn: 1000 * 60 * 60 * 24, // 24 hours
        });
        this.client = createClient();
    }

    async loadCollections() {
        // First check in sessionStorage
        const existing = sessionStorage.getItem("hp_collections");
        if (existing) {
            this.collections = JSON.parse(existing);
            return;
        }

        const { collections } = await this.client.get<CollectionListResponse>(`/collections`);
        sessionStorage.setItem("hp_collections", JSON.stringify(collections));
        this.collections = collections;
    }

    async loadCollection(slug: string) {
        this.collection = null;
        this.episodes = [];
        this.loading = true;
        this.page = 1;

        const { collection } = await this.client.get<CollectionResponse>(`/collections/bySlug/${slug}`);
        this.collection = collection;
        await this.loadEpisodes(collection.id);
        this.loading = false;
    }

    async loadEpisodes(id: number) {
        const { episodes } = await this.client.get<EpisodesResponse>(`/collections/${id}/episodes`);
        this.episodes = episodes;
    }

    async loadNext() {
        if (this.collection) {
            this.loading = true;
            this.page += 1;
            const { episodes } = await this.client.get<EpisodesResponse>(
                `/collections/${this.collection.id}/episodes?page=${this.page}`
            );
            this.episodes = [...this.episodes, ...episodes];
            this.loading = false;
        }
    }
}
