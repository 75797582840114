import { Outlet } from "react-router-dom";
import { Player } from "./Player";
import { PlaylistModal } from "./PlaylistModal";
import styles from "./styles/Layout.module.css";

export const Layout = () => {
    return (
        <div className={"main " + styles.main}>
            <div className="container">
                <Outlet />
            </div>
            <Player key="7" />
            <PlaylistModal />
        </div>
    );
};
