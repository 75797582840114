import { makeAutoObservable } from "mobx";
import { collection, CollectionReference, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { makePersistable } from "mobx-persist-store";
import { db } from "@/core/firestore";
import { getCurrentUserId } from "@/core/firebase";

export class RatingStore {
    private readonly collection: CollectionReference;
    public ratings: Rating[] = [];
    private podcastRatings: PodcastRating[] = [];
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: "RatingStore",
            properties: ["ratings"],
            storage: window.sessionStorage,
        });
        this.collection = collection(db, "ratings");
    }

    async loadMyRatings() {
        const myId = getCurrentUserId();
        if (!myId) {
            this.ratings = [];
            return;
        }
        const q = query(this.collection, where("userId", "==", myId));
        const snapshot = await getDocs(q);
        this.ratings = snapshot.docs.map((doc) => doc.data() as Rating);
        this.podcastRatings = [];
    }

    hasRated(podcastId: number) {
        return this.ratings.some((rating) => rating.podcast === podcastId);
    }

    async getRatings(podcastId: number): Promise<RatingStats> {
        let podcastRatings = this.podcastRatings.find((r) => r.podcast === podcastId);
        if (!podcastRatings) {
            const q = query(this.collection, where("podcast", "==", podcastId));
            const snapshot = await getDocs(q);
            const ratings: Rating[] = snapshot.docs.map((doc) => doc.data() as Rating);
            podcastRatings = {
                podcast: podcastId,
                ratings,
            };
            this.podcastRatings.push(podcastRatings);
        }

        const average =
            podcastRatings.ratings.reduce((acc, rating) => acc + rating.rating, 0) / podcastRatings.ratings.length;

        // Make sure average only has one decimal
        return {
            ratings: podcastRatings.ratings,
            average: average ? Math.round(average * 10) / 10 : 0,
        };
    }

    getRating(podcastId: number) {
        const rating = this.ratings.find((rating) => rating.podcast === podcastId);
        return rating ? rating.rating : 0;
    }

    getAverageRating(podcastId: number) {
        return podcastId;
    }

    getRatingCount(podcastId: number) {
        return podcastId;
    }

    async rate(rating: Rating) {
        // Check in firestore if user has already rated this podcast
        const q = query(this.collection, where("userId", "==", rating.userId), where("podcast", "==", rating.podcast));
        const snapshot = await getDocs(q);
        if (snapshot.docs.length > 0) {
            // Update existing rating
            const doc = snapshot.docs[0];
            await setDoc(doc.ref, rating);
            await this.loadMyRatings();
            return;
        }

        await setDoc(doc(this.collection), rating);
        await this.loadMyRatings();
    }
}
