import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createClient } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";

export class SubjectStore {
    private client: ApiClient;
    public subjects: Subject[] = [];
    public subject: null | Subject = null;
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: "SubjectStore",
            properties: ["subjects"],
            storage: window.localStorage,
            expireIn: 1000 * 60 * 60 * 24, // 24 hours
        });
        this.client = createClient();
    }

    async loadSubjects() {
        const { subjects } = await this.client.get<SubjectsResponse>(`/subjects`);
        this.subjects = subjects;
    }

    async loadSubject(slug: string) {
        this.subject = null;
        const { subject } = await this.client.get<SingleSubjectResponse>(`/subjects/${slug}`);
        this.subject = subject;
    }
}
