import { useState } from "react";
import { Blurhash } from "react-blurhash";
import { getPodcastImage, ImageSizes } from "@/core/helpers";

const sizes = {
    small: 64,
    medium: 92,
    large: 128,
};

type PodcastImageProps = {
    podcast: Podcast;
    size?: number;
};

export const PodcastImage = ({ podcast, size }: PodcastImageProps) => {
    const img = getPodcastImage(podcast, ImageSizes.Small);
    const width = size ?? sizes.medium;
    const [imageLoaded, setImageLoaded] = useState(false);
    const imgRef = new Image();
    imgRef.onload = () => setImageLoaded(true);
    imgRef.src = img;
    return imageLoaded ? (
        <img loading={"lazy"} width={width} height={width} src={img} alt={podcast.name} title={podcast.name} />
    ) : (
        <div>
            <Blurhash hash={podcast.blurhash} width={width} height={width} resolutionX={4} resolutionY={4} />
        </div>
    );
};
