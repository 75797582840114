import { Link } from "react-router-dom";
import { useState } from "react";
import styles from "./styles/SquareOverview.module.css";
import { SquarePlaceholder } from "./SquarePlaceholder";
import { getPodcastImage, ImageSizes } from "@/core/helpers";

interface SquareOverviewProps {
    podcasts: Podcast[];
    footer?: React.JSX.Element | null;
    header?: string;
    subheader?: string;
    description?: string;
}

interface SquareProps {
    podcast: Podcast;
    size?: "small" | "medium" | "large";
}

const Square = ({ podcast, size = "medium" }: SquareProps) => {
    let img = getPodcastImage(podcast, ImageSizes.Large);
    let width = ImageSizes.Large;
    if (size === "small") {
        img = getPodcastImage(podcast, ImageSizes.Small);
        width = ImageSizes.Small;
    }

    if (size === "medium") {
        img = getPodcastImage(podcast, ImageSizes.Medium);
        width = ImageSizes.Medium;
    }

    const [imageLoaded, setImageLoaded] = useState(false);
    const imgRef = new Image();
    imgRef.onload = () => setImageLoaded(true);
    imgRef.src = img;

    return imageLoaded ? (
        <Link to={"/podd/" + podcast.slug} className={styles.square}>
            {(size === "medium" || size === "large") && (
                <div className={styles.overlay}>
                    <span>{podcast.name}</span>
                </div>
            )}
            <img loading={"eager"} width={width} height={width} src={img} alt={podcast.name} title={podcast.name} />
        </Link>
    ) : (
        <SquarePlaceholder />
    );
};

export const SquareOverview = ({ podcasts, footer = null, header, subheader, description }: SquareOverviewProps) => {
    return podcasts.length > 4 ? (
        <>
            <div className={styles.elm}>
                <div className={styles.wrap}>
                    <div className={styles.square}>
                        <div className={styles.wrap}>
                            <Square podcast={podcasts[1]} />
                            <Square podcast={podcasts[2]} />
                        </div>
                        <div className={styles.wrap}>
                            <Square podcast={podcasts[3]} />
                            <Square podcast={podcasts[4]} />
                        </div>
                    </div>
                    <Square podcast={podcasts[0]} size={"large"} />
                </div>
                {(header || subheader) && (
                    <div className={styles.header}>
                        <div className={styles.hgroup}>
                            {header && <h1 className={styles.h1}>{header}</h1>}
                            {subheader && <h2 className={styles.h2}>{subheader}</h2>}
                            {description && <p className={styles.description}>{description}</p>}
                        </div>
                    </div>
                )}
                {footer}
            </div>
        </>
    ) : (
        <div className={styles.elm}>
            <div className={styles.elm}>
                <div className={styles.wrap}>
                    <div className={styles.square}>
                        <div className={styles.wrap}>
                            <SquarePlaceholder />
                            <SquarePlaceholder />
                        </div>
                        <div className={styles.wrap}>
                            <SquarePlaceholder />
                            <SquarePlaceholder />
                        </div>
                    </div>
                    <SquarePlaceholder size={"large"} />
                </div>
            </div>
        </div>
    );
};
