import { makeAutoObservable, observe } from "mobx";
import { createClient, localeToShort } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";

export class PodcastStore {
    podcasts: Podcast[] = [];
    podcast: Podcast | null = null;
    episodes: Episode[] = [];
    episode: Episode | null | undefined = null;
    count = 0;
    page = 1;
    limit = 30;
    loading = false;
    loadingEpisode = false;
    loadingEpisodesList = false;
    loadingSimilar = false;
    next: Episode | null = null;
    previous: Episode | null = null;
    similar: Podcast[] = [];
    stats: SiteStats | null = null;
    private client: ApiClient;
    seasons: SeasonsResponse = {
        seasons: [],
    };
    season: Season | null = null;

    constructor() {
        makeAutoObservable(this);
        this.client = createClient();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        observe(this, (change: any) => {
            if (change.name === "podcast") {
                this.seasons = {
                    seasons: [],
                };
                this.season = null;
            }
        });
    }

    async getPodcastBySlug(slug: string, page = 1) {
        if (this.podcast && this.podcast.slug === slug) {
            if (page !== this.page) {
                await this.getEpisodes(this.podcast.id, page);
            }
            return;
        }
        this.podcast = null;
        this.episodes = [];
        const { podcast } = await this.client.get<SinglePodcastResponse>(`/podcasts/bySlug/${slug}`);
        this.podcast = podcast;

        await this.getEpisodes(podcast.id, page);
    }

    async getPodcast(id: number) {
        const { podcast } = await this.client.get<SinglePodcastResponse>(`/podcasts/${id}`);
        this.podcast = podcast;
    }

    async getPodcastFull(id: number) {
        const { podcast } = await this.client.get<SinglePodcastResponse>(`/podcasts/${id}`);
        const { episodes } = await this.client.get<EpisodesResponse>(`/podcasts/${id}/episodes`);
        this.podcast = podcast;
        this.episodes = episodes;
    }

    async getEpisodeBySlug(slug: string, blogSlug: string) {
        this.loadingEpisode = true;
        if (!this.podcast || this.podcast.slug !== blogSlug) {
            await this.getPodcastBySlug(blogSlug);
        }

        if (!this.podcast) {
            return;
        }

        const { episode, next, previous } = await this.client.get<SingleEpisodeResponse>(
            `/podcasts/${this.podcast.id}/episodes/${slug}`
        );
        this.episode = episode;
        this.next = next || null;
        this.previous = previous || null;
        this.loadingEpisode = false;
    }

    async getEpisodes(id: number, page = 1) {
        this.loadingEpisodesList = true;
        this.episodes = [];
        this.page = page;
        const { episodes, count } = await this.client.get<EpisodesResponse>(`/podcasts/${id}/episodes?page=${page}`);
        this.count = count || 0;
        this.episodes = episodes;
        this.loadingEpisodesList = false;
    }

    async loadSimilar(podcast: Podcast) {
        this.loadingSimilar = true;
        this.similar = [];
        const lang = podcast.language ? localeToShort(podcast.language) : "sv";
        const { podcasts } = await this.client.get<PodcastsResponse>(
            `/podcasts/${podcast.id}/similar?lang=${lang}&count=12`
        );
        const entries = Object.entries(podcasts);
        this.similar = entries
            .sort(() => 0.5 - Math.random())
            .map((item) => {
                return item[1];
            });
        this.loadingSimilar = false;
    }

    async loadStats() {
        const { stats } = await this.client.get<StatsResponse>("/podcasts/stats");
        this.stats = stats;
    }

    async loadLatest(limit = 30, lang = "sv") {
        const { podcasts } = await this.client.get<PodcastsResponse>(
            `/podcasts?sort=created_at&dir=desc&limit=${limit}&pod=1&lang=${lang}`
        );
        this.podcasts = podcasts;
    }

    async loadSeasons() {
        if (!this.podcast) return;
        this.seasons = await this.client.get<SeasonsResponse>(`/podcasts/${this.podcast.id}/seasons`);
    }

    async loadSeason(seasonId: number) {
        if (!this.podcast) return;
        this.season = await this.client.get<SeasonResponse>(`/podcasts/${this.podcast.id}/seasons/${seasonId}`);
    }

    clearSimilar() {
        this.similar = [];
    }

    get popularPodcasts(): Podcast[] {
        if (!this.stats) return [];
        return this.stats.popular.podcasts.podcasts.slice(0, 5);
    }

    get popularEpisodes(): Episode[] {
        if (!this.stats) return [];
        return this.stats.popular.episodes.episodes.slice(0, 5);
    }
}
