import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { truncate } from "@/core/helpers";
import { useStores } from "@/hooks/useStores";

interface EpisodeLinkProps {
    episode: Episode;
    podcast: Podcast;
    shorten?: boolean;
}

export const EpisodeLink = observer(({ episode, podcast, shorten = false }: EpisodeLinkProps) => {
    const { playlistStore } = useStores();
    return (
        <Link
            to={"/podd/" + podcast.slug + "/" + episode.slug}
            onClick={() => {
                playlistStore.close();
            }}>
            {shorten ? truncate(episode.title, 68) : episode.title}
        </Link>
    );
});
