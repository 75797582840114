import { Link } from "react-router-dom";
import styles from "./styles/EpisodeListItem.module.css";
import { PlayButton } from "./PlayButton";
import { PodcastImage } from "./PodcastImage";
import { filterTitle, timeAgo } from "@/core/helpers";

interface EpisodeListItemProps {
    episode: Episode;
    podcast: Podcast;
}

export const EpisodeListItem = ({ podcast, episode }: EpisodeListItemProps) => {
    const isVideo = episode.sound.endsWith(".mp4");
    return (
        <div className={styles.wrap}>
            <div className={styles.item}>
                <div className={styles.image}>
                    {<PodcastImage podcast={podcast} size={72} />}
                    {!isVideo && (
                        <div className={styles.play}>
                            <PlayButton size={"tiny"} key={episode.guid} podcast={podcast} episode={episode} />
                        </div>
                    )}
                </div>

                <div className={styles.info}>
                    {isVideo && <span className={styles.label}>Video</span>}
                    <h3 className={styles.header} title={episode.title}>
                        <Link
                            to={"/podd/" + podcast.slug + "/" + episode.slug}
                            dangerouslySetInnerHTML={{
                                __html: filterTitle(episode.title),
                            }}
                        />
                    </h3>
                    <h4 className={styles.subheader}>
                        <Link to={"/podd/" + podcast.slug}>{podcast.name}</Link>
                    </h4>
                    <p className={styles.meta}>
                        <time className={styles.time} dateTime={episode.published}>
                            {episode.published.substring(0, 10)} - {timeAgo(episode.published)}
                        </time>
                    </p>
                </div>
            </div>
        </div>
    );
};
