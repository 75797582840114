import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styles from "./styles/RangeSlider.module.css";

type RangeSliderProps = {
    value: number;
    min: number;
    max: number;
    onChange: (value: number | number[]) => void;
};

export const RangeSlider = ({ value, min, max, onChange }: RangeSliderProps) => {
    return (
        <div className={styles.wrapper}>
            <Slider
                min={min}
                max={max}
                value={value}
                draggableTrack={false}
                trackStyle={{ backgroundColor: "#EC407A", height: 2 }}
                handleStyle={{
                    borderColor: "#F06292",
                    borderWidth: 0,
                    backgroundColor: "#EC407A",
                    opacity: 1,
                    marginTop: -6,
                }}
                railStyle={{ backgroundColor: "rgba(0, 0, 0,0.5)", height: 2 }}
                onChange={onChange}
            />
        </div>
    );
};
