import { AiOutlineLoading } from "react-icons/ai";
import styles from "./styles/PageLoading.module.css";

export const PageLoading = () => {
    return (
        <div className={styles.page}>
            <AiOutlineLoading className={styles.spinner} size={64} color={"#E1BEE7"} />
        </div>
    );
};
