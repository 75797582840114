import * as React from "react";
import clsx from "clsx";
import { ReactElement } from "react";
import styles from "./styles/ActionButton.module.css";

type ActionButtonProps = {
    icon: ReactElement;
    label?: string | null;
    onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
    href?: string | null;
    variant?: "primary" | "secondary" | "clean";
} & React.HTMLAttributes<HTMLAnchorElement>;

export const ActionButton = ({
    icon,
    label = null,
    href = null,
    onClick,
    variant = "primary",
    ...props
}: ActionButtonProps): ReactElement => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (onClick) {
            event.preventDefault();
            onClick(event);
        }
    };

    const wrapClasses = clsx(styles.wrap, styles[variant]);

    return (
        <div className={wrapClasses}>
            <a href={href ? href : "#"} onClick={handleClick} {...props}>
                {icon}
                {label ? " " + label : ""}
            </a>
        </div>
    );
};
