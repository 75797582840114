import { observer } from "mobx-react";
import * as React from "react";
import clsx from "clsx";
import styles from "./styles/Content.module.css";
import { useStores } from "@/hooks/useStores";

interface ContentProps {
    children: React.ReactNode;
}

export const Content = observer(({ children }: ContentProps) => {
    const { playerStore } = useStores();
    const cls = clsx(styles.content, {
        [styles.player]: playerStore.active,
    });
    return <div className={cls}>{children}</div>;
});
