import { makeAutoObservable } from "mobx";

interface BookProvider {
    url: string;
    name: string;
    free: number;
    cost: number;
    description: string;
    domain: string;
}

interface BookProvidersInterface {
    [name: string]: BookProvider;
}

export class AdStore {
    bookProviders: BookProvidersInterface;
    constructor() {
        makeAutoObservable(this);
        this.bookProviders = {
            bookbeat: {
                url: "https://in.bookbeat.se/t/t?a=1529694437&as=1628518118&t=2&tk=1",
                name: "BookBeat",
                free: 30,
                cost: 99,
                description:
                    "Bästa böckerna just nu! Vi har böcker för alla åldrar och smaker - välj bland romaner, deckare, barnböcker, biografier, feelgood eller från dina personliga boktips som du hittar i appen.",
                domain: "www.bookbeat.se",
            },
            nextory: {
                url: "https://pin.nextory.se/t/t?a=1105175392&as=1628518118&t=2&tk=1",
                name: "Nextory",
                free: 30,
                cost: 139,
                description:
                    "Koppla av med bra historier. Hitta dina och barnens favoriter bland hundratusentals ljudböcker och e-böcker.",
                domain: "www.nextory.se",
            },
            storytel: {
                url: "https://track.adtraction.com/t/t?a=1594170599&as=1628518118&t=2&tk=1",
                name: "Storytel",
                free: 30,
                cost: 179,
                description:
                    "Sätt fart på fantasin med ljudböcker! Öppna dörren till en oändlig värld av ljudböcker och stories. Upptäck prisbelönta deckare, svindlande romaner eller mäktiga biografier, och sortera enkelt böcker på både svenska, engelska, arabiska, danska eller finska.",
                domain: "www.storytel.com/se/sv/",
            },
        };
    }

    random(): BookProvider {
        const keys = Object.keys(this.bookProviders);
        return this.bookProviders[keys[(keys.length * Math.random()) << 0]];
    }
}
