import { createContext, ReactElement } from "react";
import { Howl } from "howler";

export const HowlerContext = createContext({});

export type HowlerState = {
    howl: Howl | undefined;
    setHowl: (src: string) => void;
};
// eslint-disable-next-line react/prop-types
export const HowlerProvider = ({ children }: { children: ReactElement }) => {
    const howlState: HowlerState = {
        howl: undefined,
        setHowl(src: string) {
            this.howl = new Howl({
                src: src,
                html5: true,
                autoplay: true,
            });
        },
    };

    return <HowlerContext.Provider value={howlState}>{children}</HowlerContext.Provider>;
};
