import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { AiOutlineDelete } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
import { TbArrowsSort } from "react-icons/tb";
import { Form } from "react-bootstrap";
import { EpisodeLink } from "./EpisodeLink";
import styles from "./styles/PlaylistModal.module.css";
import { PlaylistItem } from "@/stores/PlaylistStore";
import { useStores } from "@/hooks/useStores";
import { createDurationMin, getPodcastImage, ImageSizes } from "@/core/helpers";

export const PlaylistModal = observer(() => {
    const { playlistStore } = useStores();
    const { episodes } = playlistStore;
    const [filterText, setFilterText] = useState<string>("");

    const stls = {
        transform: playlistStore.isOpen ? "translateX(0px)" : "translateX(360px)", //display: playlistStore.isOpen ? "block" : "none",
        opacity: playlistStore.isOpen ? 1 : 0,
        transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
    };

    const doSort = () => {
        playlistStore.toggleSort();
    };

    useEffect(() => {
        playlistStore.filterEpisodes(filterText);
    }, [filterText, playlistStore]);

    const coll = filterText.length > 0 ? playlistStore.filtered : episodes;

    return (
        <>
            {episodes.length > 0 && (
                <div className={styles.filter} style={stls}>
                    <span>Spellista</span>
                    <Form.Control
                        value={filterText}
                        onChange={(event) => {
                            setFilterText(event.target.value);
                        }}
                        size={"sm"}
                        type="search"
                        placeholder="Sök..."
                    />
                    <span onClick={doSort}>
                        <TbArrowsSort />
                    </span>
                </div>
            )}
            <div className={styles.wrapper} style={stls}>
                {episodes.length === 0 && (
                    <div className={styles.item}>
                        <div className={styles.img}>
                            <BsImage size={48} opacity={0.5} />
                        </div>
                        <div className={styles.about + " text-muted"}>
                            Du lägger till episoder här genom att klicka "Spela senare" på avsnitt du vill lägga till.
                        </div>
                    </div>
                )}
                {coll.map((item: PlaylistItem) => {
                    return (
                        <div className={styles.item} key={"playlist-" + item.episode.slug}>
                            <div className={styles.img}>
                                <img
                                    alt={item.episode.title}
                                    src={getPodcastImage(item.podcast, ImageSizes.Thumb)}
                                    width={64}
                                    height={64}
                                    loading={"lazy"}
                                />
                            </div>
                            <div className={styles.about}>
                                <EpisodeLink shorten={true} episode={item.episode} podcast={item.podcast} />
                                <div className={styles.podcast}>{item.podcast.name}</div>
                                <p className={styles.meta}>
                                    {item.episode.published.slice(0, 10)} - {createDurationMin(item.episode.length)}{" "}
                                    minuter
                                </p>
                            </div>
                            <div className={styles.actions}>
                                <AiOutlineDelete
                                    size={18}
                                    style={{ cursor: "pointer", opacity: 0.5 }}
                                    onClick={async () => {
                                        if (window.confirm("Vill du ta bort detta avsnitt från spellistan?")) {
                                            await playlistStore.remove(item.episode);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
});
