import { useMemo } from "react";
import { AiOutlineUser } from "react-icons/ai";
import styles from "./styles/UserIcon.module.css";
import { getCurrentUserImageUrl } from "@/core/firebase";

const FallBackImage = () => {
    return (
        <span className={styles.fallback}>
            <AiOutlineUser size={21} />
        </span>
    );
};

export function UserIcon() {
    const image = useMemo(() => {
        return getCurrentUserImageUrl();
    }, []);

    return (
        <div className={styles.wrapper}>
            {!image && <FallBackImage />}
            {image && <img src={image} className={styles.image} alt={"User icon"} />}
        </div>
    );
}
