import { NavHashLink } from "react-router-hash-link";
import clsx from "clsx";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { ReactNode } from "react";
import styles from "./styles/PodcastListItem.module.css";
import { getPodcastImage, ImageSizes } from "@/core/helpers";

type LoadingType = "eager" | "lazy";

interface PodcastListItemProps {
    podcast: Podcast;
    addon?: ReactNode | null;
    showReactions?: boolean;
    suffix?: ReactNode | null;
    imageLoading?: LoadingType;
    anchor?: string;
}

export const PodcastListItem = ({
    podcast,
    addon = null,
    showReactions = false,
    suffix = null,
    imageLoading = "lazy",
    anchor = "",
}: PodcastListItemProps) => {
    const classes = clsx(["list-group-item", "d-flex", "align-items-center", styles.item]);
    const extras: Record<string, string> = {};
    if (anchor) {
        extras["id"] = anchor;
    }
    return (
        <NavHashLink className={classes} to={"/podd/" + podcast.slug} {...extras}>
            <img
                className={styles.catimg}
                src={getPodcastImage(podcast, ImageSizes.Thumb)}
                loading={imageLoading}
                alt={podcast.name + " bild"}
            />
            <div className={styles.label}>
                <div className={styles.name}>{podcast.name}</div>
                {podcast.categories && (
                    <ul className={styles.cat}>
                        {podcast.categories
                            .filter((c: Category) => c.slug !== "podcaster")
                            .map((c: Category, i: number) => (
                                <li key={`subcat-${c.id}-${i}`}>{c.name}</li>
                            ))}
                    </ul>
                )}
                {podcast.author && <p className={styles.author}>{podcast.author.name}</p>}
                {!!podcast.episodes_count && <small>{podcast.episodes_count} episoder</small>}
                {showReactions && (
                    <p className={"mt-4"}>
                        <AiOutlineArrowUp
                            style={{
                                position: "relative",
                                top: "-1px",
                            }}
                            color={"#a8a8a8"}
                            size={18}
                        />{" "}
                        <AiOutlineArrowDown
                            style={{
                                position: "relative",
                                top: "1px",
                            }}
                            color={"#a8a8a8"}
                            size={18}
                        />
                    </p>
                )}
            </div>
            {addon && <div className={styles.addon}>{addon}</div>}
            {suffix && <div className={styles.suffix}>{suffix}</div>}
        </NavHashLink>
    );
};
