import { useContext } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { BsFillPlayFill, BsPauseFill } from "react-icons/bs";
import styles from "./styles/PlayButton.module.css";
import { ActionButton } from "./ActionButton";
import { useStores } from "@/hooks/useStores";
import { HowlerContext } from "@/context/howler";
import { trackEvent } from "@/core/analytics";

export const PlayButton = observer(
    ({ episode, podcast, labels = null, size = "medium", asText = false }: PlayButtonProps) => {
        const { playerStore } = useStores();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const sound = useContext<any>(HowlerContext);

        const play = (event: React.MouseEvent<HTMLSpanElement>) => {
            if (event) {
                event.preventDefault();
            }
            if (playerStore.currentEpisode.sound !== episode.sound) {
                // Only if sound has been set, the sound will first pause before moving on
                if (playerStore.currentEpisode.sound !== "") {
                    sound.howl.pause();
                }
                sound.setHowl(episode.sound);
                playerStore.setCurrentEpisode(episode, podcast);
            }
            const currentProgress = parseInt(sound.howl.seek());
            trackEvent({
                category: "Player",
                action: "Play",
                label: `${podcast.slug}-${episode.slug}`,
                value: currentProgress,
            });
            playerStore.play(currentProgress);
            sound.howl.play();
        };

        const pause = (event: React.MouseEvent<HTMLSpanElement>) => {
            if (event) {
                event.preventDefault();
            }
            const currentProgress = parseInt(sound.howl.seek());
            trackEvent({
                category: "Player",
                action: "Pause",
                label: `${podcast.slug}-${episode.slug}`,
                value: currentProgress,
            });
            playerStore.pause(currentProgress);
            sound.howl.pause();
        };

        const isPlaying = playerStore.currentEpisode.title === episode.title && playerStore.isPlaying;

        const btnClass = clsx(styles[size], {
            [styles.btn]: !labels,
            [styles.txt]: labels,
            [styles.playing]: isPlaying,
        });

        const showPlayOrPause = () => {
            if (isPlaying) {
                if (asText) {
                    return (
                        <span className={styles.text} onClick={pause}>
                            <BsPauseFill size={18} /> Pausa
                        </span>
                    );
                }
                return labels ? (
                    <ActionButton icon={<BsPauseFill fontSize={"inherit"} />} onClick={pause} label={labels[1]} />
                ) : (
                    <a href={"#pause"} className={btnClass} onClick={pause}>
                        <BsPauseFill size={18} key="3" className="play-arrow-icon" />
                    </a>
                );
            } else {
                if (asText) {
                    return (
                        <span className={styles.text} onClick={play}>
                            <BsFillPlayFill fontSize={18} /> Spela
                        </span>
                    );
                }
                return labels ? (
                    <ActionButton icon={<BsFillPlayFill fontSize={"inherit"} />} onClick={play} label={labels[0]} />
                ) : (
                    <a href={"#play"} className={btnClass} onClick={play}>
                        <BsFillPlayFill fontSize={18} key="2" className="play-arrow-icon" />
                    </a>
                );
            }
        };

        return <>{showPlayOrPause()}</>;
    }
);
