import { Button } from "react-bootstrap";
import * as React from "react";
import { observer } from "mobx-react";
import styles from "./styles/Gdpr.module.css";
import { useStores } from "@/hooks/useStores";
export const Gdpr = observer(() => {
    const { userStore } = useStores();
    const approve = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        userStore.setGdprApproved(true);
    };
    return !userStore.gdprApproved ? (
        <div className={styles.wrap}>
            <div className={styles.inner}>
                <h3>Cookies och Analys</h3>
                <p>
                    Vi sparar information på din klient i form av cookies och liknande mekanismer. Vi använder även
                    analysverktyg där icke-personlig användardata sparas för att hjälpa oss att se hur tjänsten används
                    och förenkla att avhjälpa eventuella fel som kan uppstå i tjänsten.
                </p>
                <Button onClick={approve}>Det är ok med mig</Button>
            </div>
        </div>
    ) : null;
});
