import { observer } from "mobx-react";
import { StandaloneEpisode } from "./StandaloneEpisode";
import { EpisodeListItem } from "./EpisodeListItem";

interface EpisodeProps {
    episode: Episode;
    podcast: Podcast | undefined;
    isEpisode: boolean;
}

export const EpisodeItem = observer(({ episode, podcast, isEpisode = false }: EpisodeProps) => {
    if (!podcast) return null;
    return isEpisode ? (
        <StandaloneEpisode episode={episode} podcast={podcast} />
    ) : (
        <EpisodeListItem episode={episode} podcast={podcast} />
    );
});
