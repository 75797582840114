import { makeAutoObservable } from "mobx";
import {
    doc,
    setDoc,
    collection,
    CollectionReference,
    query,
    where,
    getCountFromServer,
    orderBy,
    getDocs,
} from "firebase/firestore";
import { db } from "@/core/firestore";

export class CommentStore {
    private readonly collection: CollectionReference;
    private podcastCount: PodcastCount[] = [];
    private podcastComments: PodcastComments[] = [];
    constructor() {
        makeAutoObservable(this);
        this.collection = collection(db, "comments");
    }

    async loadCommentCount(podcastId: number) {
        const existing = this.podcastCount.find((c) => c.podcast === podcastId);
        if (existing) {
            return existing.count;
        }

        const q = query(this.collection, where("podcast", "==", podcastId));
        const snapshot = await getCountFromServer(q);

        this.podcastCount.push({
            podcast: podcastId,
            count: snapshot.data().count,
        });
    }

    getCommentCount(podcastId: number) {
        const existing = this.podcastCount.find((c) => c.podcast === podcastId);
        if (existing) {
            return existing.count;
        }
        return 0;
    }

    async incrementOnNewComment(podcastId: number) {
        const existing = this.podcastCount.find((c) => c.podcast === podcastId);
        if (existing) {
            existing.count++;
        }
    }

    async loadComments(podcastId: number, force = false) {
        const comments = this.podcastComments.find((c) => c.podcast === podcastId);
        if (comments && !force) {
            return comments.comments;
        }
        const podcastComments: PodcastComment[] = [];
        const q = query(this.collection, where("podcast", "==", podcastId), orderBy("timestamp", "desc"));
        const snapshot = await getDocs(q);
        snapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const { comment, podcast, timestamp, userId, name } = doc.data();
            const commentObj: PodcastComment = {
                comment,
                podcast,
                timestamp,
                userId,
                name,
            };
            podcastComments.push(commentObj);
        });

        // If already in cache (with force) make sure we dont have multiple copies
        const existing = this.podcastComments.find((c) => c.podcast === podcastId);
        if (existing) {
            const index = this.podcastComments.indexOf(existing);
            this.podcastComments.splice(index, 1);
        }

        this.podcastComments.push({
            podcast: podcastId,
            comments: podcastComments,
        });
        return podcastComments;
    }

    getComments(podcastId: number) {
        const comments = this.podcastComments.find((c) => c.podcast === podcastId);
        if (comments) {
            return comments.comments;
        }
        return [];
    }

    async addComment(comment: PodcastComment) {
        await this.incrementOnNewComment(comment.podcast);
        await setDoc(doc(this.collection), comment);
        await this.loadComments(comment.podcast, true);
    }
}
