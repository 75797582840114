import { ReactNode } from "react";
import clsx from "clsx";
import styles from "./styles/Paper.module.css";

type PaperVariant = "primary" | "secondary" | "warning" | "success" | "danger" | "dark";

export function Paper(props: {
    children: ReactNode;
    variant?: PaperVariant | null;
    floatingHeader?: string | undefined | null;
}) {
    const type = props.variant ?? "primary";
    const paperClasses = clsx(styles.paper, styles[type], {
        [styles.pt]: props.floatingHeader && props.floatingHeader.length > 0,
    });
    return (
        <div className={paperClasses}>
            {props.floatingHeader ? <h4 className={styles.header}>{props.floatingHeader}</h4> : null}
            {props.children}
        </div>
    );
}
