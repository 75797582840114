import { makeAutoObservable } from "mobx";
import Pubsub from "pubsub-js";
import { createClient, createDurationSec, localSave } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";

const currentEpisode: Episode = {
    art: "",
    content: "",
    created_at: "",
    explicit: 0,
    guid: "",
    link: "",
    podcast_id: 0,
    season: 0,
    snippet: "",
    type: "",
    updated_at: "",
    id: -1,
    sound: "",
    title: "",
    length: "0",
    duration: 0,
    slug: "",
    published: "",
};

const currentAuthor: Author = {
    art: "",
    content: "",
    copyright: "",
    created_at: "",
    id: 0,
    name: "",
    slug: "",
    updated_at: "",
    url: "",
};

const currentPodcast: Podcast = {
    author: currentAuthor,
    blurhash: "",
    categories: [],
    created_at: "",
    description: "",
    feed: "",
    itunes_id: 0,
    release_date: "",
    summary: "",
    updated_at: "",
    url: "",
    website: "",
    name: "",
    slug: "",
    art: "",
    id: -1,
};

const defaultState = {
    currentEpisode,
    currentPodcast,
};

export class PlayerStore {
    isPlaying = false;
    active = false;
    currentEpisode: Episode = defaultState.currentEpisode;
    currentPodcast: Podcast = defaultState.currentPodcast;
    private client: ApiClient;

    constructor() {
        makeAutoObservable(this);
        this.client = createClient();
    }

    get seconds() {
        return createDurationSec(this.currentEpisode.length);
    }

    setCurrentEpisode(episode: Episode, podcast: Podcast) {
        this.currentEpisode = episode;
        this.currentPodcast = podcast;
        this.saveToHistory(podcast, episode);
    }

    play(progress: number) {
        this.active = true;
        this.isPlaying = true;
        Pubsub.publish("player/play", {
            episode: this.currentEpisode,
            podcast: this.currentPodcast,
            progress,
        });
        this.trackPlayerEvents("play", {
            episode: this.currentEpisode.id,
            podcast: this.currentPodcast.id,
            progress,
        }).catch((e) => {
            console.log(e);
        });
    }

    pause(progress: number) {
        this.isPlaying = false;
        Pubsub.publish("player/pause", {
            episode: this.currentEpisode,
            podcast: this.currentPodcast,
            progress,
        });
        this.trackPlayerEvents("pause", {
            episode: this.currentEpisode.id,
            podcast: this.currentPodcast.id,
            progress,
        }).catch((e) => {
            console.log(e);
        });
    }

    saveToHistory(podcast: Podcast, episode: Episode) {
        localSave("hp_pod_history", podcast.id, 5).catch(() => {
            console.error("Could not save podcast to history");
        });
        localSave("hp_episode_history", episode.id, 5).catch(() => {
            console.error("Could not save episode to history");
        });
        Pubsub.publish("user/history");
    }

    async trackPlayerEvents(action: string, payload = {}) {
        if ("plausible" in window) {
            window.plausible(action);
        }
        await this.client.post(`/episodes/${this.currentEpisode.id}/action`, {
            action,
            payload,
            client: window["clientId"] ?? "",
        });
        Pubsub.publish(`player/${action}`);
    }

    close() {
        this.isPlaying = false;
        this.active = false;
        this.currentPodcast = defaultState.currentPodcast;
        this.currentEpisode = defaultState.currentEpisode;
    }
}
