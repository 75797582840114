import { createContext } from "react";
import { CategoryStore } from "@/stores/CategoryStore";
import { PodcastStore } from "@/stores/PodcastStore";
import { SearchStore } from "@/stores/SearchStore";
import { PlayerStore } from "@/stores/PlayerStore";
import { EpisodeStore } from "@/stores/EpisodeStore";
import { AuthorStore } from "@/stores/AuthorStore";
import { UserStore } from "@/stores/UserStore";
import { LanguageStore } from "@/stores/LanguageStore";
import { AppStore } from "@/stores/AppStore";
import { SubjectStore } from "@/stores/SubjectStore";
import { AdStore } from "@/stores/AdStore";
import { CollectionStore } from "@/stores/CollectionStore";
import { PlaylistStore } from "@/stores/PlaylistStore";
import { ToplistStore } from "@/stores/ToplistStore";
import { CommentStore } from "@/stores/CommentStore";
import { RatingStore } from "@/stores/RatingStore";

const podcastStore = new PodcastStore();
const categoryStore = new CategoryStore();
const subjectStore = new SubjectStore();
const searchStore = new SearchStore();
const playerStore = new PlayerStore();
const episodeStore = new EpisodeStore();
const authorStore = new AuthorStore();
const userStore = new UserStore();
const languageStore = new LanguageStore();
const appStore = new AppStore();
const adStore = new AdStore();
const collectionStore = new CollectionStore();
const playlistStore = new PlaylistStore();
const toplistStore = new ToplistStore();
const commentStore = new CommentStore();
const ratingStore = new RatingStore();

export const stores = createContext({
    podcastStore,
    categoryStore,
    searchStore,
    playerStore,
    episodeStore,
    authorStore,
    userStore,
    languageStore,
    appStore,
    subjectStore,
    adStore,
    collectionStore,
    playlistStore,
    toplistStore,
    commentStore,
    ratingStore,
});
