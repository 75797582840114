import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { createClient } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";

export class LanguageStore {
    podcasts: Podcast[] = [];
    latest: Episode[] = [];
    private client: ApiClient;

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: "LanguageStore",
            properties: ["latest", "podcasts"],
            storage: window.localStorage,
            expireIn: 1000 * 60 * 60 * 24, // 24 hours
        }).catch((e) => {
            console.error("Error persisting LanguageStore", e);
        });
        this.client = createClient();
    }

    async getPodcastsByLang(lang: string) {
        const { podcasts } = await this.client.get<PodcastsResponse>(`/podcasts?lang=${lang}&pod=1`);
        this.podcasts = podcasts;
    }

    async loadLatest(lang: string, onlyToplist = 1) {
        const { episodes } = await this.client.get<EpisodesResponse>(
            `/episodes/latest?lang=${lang}&onlyToplist=${onlyToplist}`
        );
        this.latest = episodes;
    }
}
