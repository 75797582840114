import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BiHome, BiSearchAlt2 } from "react-icons/bi";
import { HiOutlineDocumentText } from "react-icons/hi";
import { ReactElement } from "react";
import styles from "./styles/AppToolbar.module.scss";

const ICON_SIZE = 24;

function IconWithText(props: { icon: ReactElement; text: string; to: string }) {
    return (
        <Link to={props.to} className={"nav-link"}>
            {props.icon}
        </Link>
    );
}

export const AppToolbar = () => (
    <div className={styles.toolbar}>
        <Navbar>
            <Container>
                <Nav className={"mr-auto"} />
                <Nav className={"mr-auto"}>
                    <IconWithText text={"Hem"} icon={<BiHome size={ICON_SIZE} />} to={"/"} />
                    <Link to={"/hitta"} className={"nav-link"}>
                        <BiSearchAlt2 size={ICON_SIZE} />
                    </Link>
                    <Link to={"/kategori"} className={"nav-link"}>
                        <HiOutlineDocumentText size={ICON_SIZE} />
                    </Link>
                </Nav>
                <Nav />
            </Container>
        </Navbar>
    </div>
);
