import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { MdForward10, MdReplay10 } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { PlayButton } from "./PlayButton";
import styles from "./styles/Player.module.css";
import { RangeSlider } from "./RangeSlider";
import { HowlerContext } from "@/context/howler";
import { trackEvent } from "@/core/analytics";
import { useStores } from "@/hooks/useStores";
import { Urls } from "@/core/urls";
import { createTimeStructure, getPodcastImage, ImageSizes } from "@/core/helpers";

export const Player = observer(() => {
    const { playerStore, userStore } = useStores();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sound: any = useContext(HowlerContext);

    // ratios is the speed of the player, fill it with some sensible values 1 is normal speed, higher is faster, lower is slower
    const ratios = [0.5, 0.75, 1, 1.25, 1.5, 2];
    const [time, setTime] = useState(0);
    const [ratio, setRatio] = useState<number>(2);

    useEffect(() => {
        const interval = setInterval(() => {
            const episode = playerStore.currentEpisode;
            if (playerStore.isPlaying) {
                userStore.ping(episode.id, Math.round(sound.howl.seek()));
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [sound, playerStore.currentEpisode, playerStore.isPlaying, userStore]);

    //Now this is continuing to fire every second, even though sound is paused,
    // which maybe isn't optimal
    useEffect(() => {
        const interval = setInterval(() => {
            if (sound.howl?.hasOwnProperty("_src")) {
                setTime(sound.howl.seek());
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [sound]);

    const changeTime = (newValue: number | number[]) => {
        setTime(Array.isArray(newValue) ? newValue[0] : newValue);
        sound.howl.seek(newValue);
        trackEvent({
            category: "Player",
            action: "SliderClick",
            label: `${playerStore.currentPodcast.slug}-${playerStore.currentEpisode.slug}`,
            value: Array.isArray(newValue) ? newValue[0] : newValue,
        });
    };

    const soundLoaded = () => sound.howl.hasOwnProperty("_src");
    const getCurrentTime = () => (soundLoaded() ? sound.howl.seek() : null);

    const close = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        playerStore.close();
        sound.howl.unload();
    };

    const jump = (jump: number) => {
        const current = getCurrentTime();
        if (current) {
            const next = Math.floor(current) + jump;
            setTime(next);
            sound.howl.seek(next);
        }
    };

    const rewind = (event: React.MouseEvent<SVGElement>) => {
        event.preventDefault();
        jump(-10);
        trackEvent({
            category: "Player",
            action: "Rewind",
            label: `${playerStore.currentPodcast.slug}-${playerStore.currentEpisode.slug}`,
        });
    };

    const forward = (event: React.MouseEvent<SVGElement>) => {
        event.preventDefault();
        jump(10);
        trackEvent({
            category: "Player",
            action: "Forward",
            label: `${playerStore.currentPodcast.slug}-${playerStore.currentEpisode.slug}`,
        });
    };

    const showRatio = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        const maxLength = ratios.length - 1;
        const next = ratio === maxLength ? 0 : ratio + 1;
        setRatio(next);
        sound.howl.rate(ratios[next]);
        trackEvent({
            category: "Player",
            action: "Ratio",
            label: `${playerStore.currentPodcast.slug}-${playerStore.currentEpisode.slug}`,
        });
    };

    return playerStore.active ? (
        <div className={styles.player}>
            <a href={"#close"} onClick={close} className={styles.close}>
                <IoMdClose />
            </a>
            <div className={styles.header}>
                <div className={styles.thumb}>
                    <Link to={Urls.podcastHome(playerStore.currentPodcast.slug)}>
                        <img
                            alt={"podcast img"}
                            loading={"lazy"}
                            className={styles.podcastimg}
                            src={getPodcastImage(playerStore.currentPodcast, ImageSizes.Small)}
                        />
                    </Link>
                </div>
                <h6>
                    <Link to={`/podd/${playerStore.currentPodcast.slug}/${playerStore.currentEpisode.slug}`}>
                        {playerStore.currentEpisode.title}
                    </Link>{" "}
                    / <Link to={`/podd/${playerStore.currentPodcast.slug}`}>{playerStore.currentPodcast.name}</Link>{" "}
                    <time className={styles.etime}>{playerStore.currentEpisode.published.substring(0, 10)}</time>
                </h6>
            </div>
            <div>
                <div className={styles.timeline}>
                    <RangeSlider value={time} onChange={changeTime} min={0} max={sound.howl.duration()} />
                </div>
                <div className={styles.time}>
                    <MdReplay10 size={32} onClick={rewind} />
                    <span>
                        <PlayButton
                            size={"tiny"}
                            key="11"
                            episode={playerStore.currentEpisode}
                            podcast={playerStore.currentPodcast}
                        />
                    </span>
                    <MdForward10 size={32} onClick={forward} />
                    <span>
                        {createTimeStructure(time)} / {createTimeStructure(sound.howl.duration())}
                    </span>
                    <span onClick={showRatio} className={styles.ratio}>
                        {Math.round(ratios[ratio] * 100)}%
                    </span>
                </div>
            </div>
        </div>
    ) : null;
});
