import { useCallback, useEffect, useMemo } from "react";
import { IoStatsChart } from "react-icons/io5";
import { observer } from "mobx-react";
import { Button, Col, ListGroup, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./styles/Home.module.css";
import { useStores } from "@/hooks/useStores";
import { EpisodeItem } from "@/components/EpisodeItem";
import { SquareOverview } from "@/components/SquareOverview";
import { Paper } from "@/components/Paper";
import { PodcastCollection } from "@/components/PodcastCollection";
import { PopularPodcasts } from "@/components/PopularPodcasts";
import { PopularEpisodes } from "@/components/PopularEpisodes";

const Home = observer(() => {
    const { episodeStore, podcastStore, toplistStore } = useStores();
    const { t } = useTranslation();

    const loadLatest = useCallback(async () => {
        await episodeStore.loadLatest();
    }, [episodeStore]);

    const loadToplist = useCallback(async () => {
        await toplistStore.loadToplist();
    }, [toplistStore]);

    const loadStats = useCallback(() => {
        if (!podcastStore.stats) {
            podcastStore.loadStats().catch(console.error);
        }
    }, [podcastStore]);

    useEffect(() => {
        loadLatest().catch((e) => console.error(e));
    }, [loadLatest]);

    useEffect(() => {
        loadStats();
    }, [loadStats]);

    useEffect(() => {
        loadToplist().catch((e) => console.error(e));
    }, [loadToplist]);

    const hasStats = !!podcastStore.stats;
    const defaultNull = <span className={styles.placeholder}></span>;

    const pod_count = hasStats ? podcastStore.stats?.podcasts.toLocaleString("sv-se") : defaultNull;
    const cat_count = hasStats ? podcastStore.stats?.categories.toLocaleString("sv-se") : defaultNull;
    const author_count = hasStats ? podcastStore.stats?.authors.toLocaleString("sv-se") : defaultNull;
    const episode_count = hasStats ? podcastStore.stats?.episodes.toLocaleString("sv-se") : defaultNull;

    const podcasts: Podcast[] = [];
    for (let i = 0; i < toplistStore.entries.length; i++) {
        const entry = toplistStore.entries[i];

        if (entry.podcast) {
            podcasts.push(entry.podcast);
        }
    }

    const twoWeeksAgo = useMemo(() => {
        const date = new Date();
        date.setDate(date.getDate() - 14);
        return date.toLocaleDateString("sv-se");
    }, []);

    const today = useMemo(() => {
        return new Date().toLocaleDateString("sv-se");
    }, []);

    return (
        <div className={styles.wrapper}>
            <SquareOverview podcasts={podcasts} />
            <Link to={"/topplista"} className={styles.toplist}>
                <IoStatsChart /> {t("home.toplist")}
            </Link>

            <h1>{t("home.header")}</h1>
            <p>{t("home.intro")}</p>

            <Row>
                <Col md={7}>
                    <Paper floatingHeader={t("home.latest.header")}>
                        {episodeStore.latest.map((episode: Episode, index: number) => {
                            return index !== 3 ? (
                                <EpisodeItem
                                    isEpisode={false}
                                    episode={episode}
                                    podcast={episode.podcast}
                                    key={"home-latest-" + index}
                                />
                            ) : null;
                        })}

                        <div className={"text-center"}>
                            <Button
                                variant={episodeStore.getting ? "link" : "outline-info"}
                                disabled={episodeStore.loading}
                                onClick={(event) => {
                                    event.preventDefault();
                                    episodeStore.loadMore().catch(console.error);
                                }}>
                                {episodeStore.getting ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            size={"sm"}
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </>
                                ) : (
                                    <>{t("home.latest.load")}</>
                                )}
                            </Button>
                        </div>
                    </Paper>
                </Col>
                <Col md={5}>
                    <div className={"mb-3"}>
                        <PodcastCollection slug={"poddar-vi-gillar"} />
                    </div>
                    <div className={styles.stats}>
                        <h5>Om HittaPodd.se</h5>
                        <strong>HittaPodd.se</strong> hjälper dig att hitta <strong>podcasts</strong> att lyssna på. För
                        oss som älskar <strong>poddar</strong> och gärna hittar nya intressanta att lyssna på. Hitta och
                        lyssna på totalt <strong>{pod_count}</strong> st poddar på svenska inom{" "}
                        <strong>{cat_count}</strong> st kategorier från <strong>{author_count}</strong> st utgivare -
                        Sammanlagt <strong>{episode_count}</strong> st episoder. Kör så det ryker. HittaPodd.se är ett
                        hobbyprojekt från{" "}
                        <a href={"https://yetric.se"} target={"out"}>
                            Yetric AB
                        </a>
                        .
                    </div>

                    <div className={"mt-3"}>
                        <ListGroup>
                            <ListGroup.Item>
                                <h4>{t("links.header")}</h4>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Link to={"/poddar"}>{t("links.latestAdded")}</Link>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Link to={"/samling"}>{t("links.collections")}</Link>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Link to={"/en"}>{t("links.englishPodcasts")}</Link>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Link to={"/topplista"}>{t("links.toplist")}</Link>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>
            </Row>

            {hasStats && (
                <div>
                    <h3>{t("home.popular.header")}</h3>
                    <p>
                        <small className={"text-muted"}>
                            {t("home.popular.meta", {
                                fromDate: twoWeeksAgo,
                                toDate: today,
                            })}
                        </small>
                    </p>
                    <Row>
                        <Col md={6}>
                            <Paper floatingHeader={t("home.popular.podcasts")}>
                                <PopularPodcasts podcasts={podcastStore.popularPodcasts} />
                            </Paper>
                        </Col>
                        <Col md={6}>
                            <Paper floatingHeader={t("home.popular.episodes")}>
                                <PopularEpisodes episodes={podcastStore.popularEpisodes} />
                            </Paper>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
});
export default Home;
