import { MdPlaylistAdd } from "react-icons/md";
import { observer } from "mobx-react";
import { ActionButton } from "./ActionButton";
import { useStores } from "@/hooks/useStores";

interface AddToPlaylistButtonProps {
    episode: Episode;
    podcast: Podcast;
}

export const AddToPlaylistButton = observer(({ episode, podcast }: AddToPlaylistButtonProps) => {
    const { playlistStore } = useStores();
    return (
        <ActionButton
            onClick={() => {
                playlistStore.add(episode, podcast);
            }}
            icon={<MdPlaylistAdd />}
            label={`Spela senare`}
            variant={"clean"}
        />
    );
});
