import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Badge, Button, Navbar, Spinner } from "react-bootstrap";
import { MdLogin, MdOutlinePlaylistPlay } from "react-icons/md";
import styles from "./styles/Header.module.css";
import { UserIcon } from "./UserIcon";
import icon from "../assets/podcast.svg";
import { useStores } from "@/hooks/useStores";

const PlaylistToggle = observer(() => {
    const { playlistStore } = useStores();
    return (
        <Button
            style={{
                opacity: playlistStore.isOpen ? 0.75 : 1,
                backgroundColor: "rgb(13,89,121)",
                borderColor: "transparent",
                outline: "none",
                position: "relative",
            }}
            onClick={(event) => {
                event.preventDefault();
                playlistStore.toggleOpen();
            }}>
            <MdOutlinePlaylistPlay size={24} />
            <Badge
                pill
                bg="danger"
                style={{
                    position: "absolute",
                    right: -9,
                    top: -9,
                    backgroundColor: "#ec407a",
                }}>
                {playlistStore.episodes.length}
            </Badge>
        </Button>
    );
});

export const Header = observer(() => {
    const navigate = useNavigate();
    function handleHome(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        navigate("/");
    }
    const { userStore, appStore } = useStores();
    return (
        <div className={styles.header}>
            <Navbar
                expand="lg"
                variant={"dark"}
                style={{
                    backgroundColor: "#11698e",
                    borderBottom: "1px solid #B3E5FC",
                }}>
                <div className="container">
                    <Navbar.Brand className={styles.brand} onClick={handleHome} href="/">
                        <img className={styles.icon} src={icon} alt="icon" />
                        HittaPodd
                    </Navbar.Brand>
                    <Navbar>
                        {userStore.isLoggedIn && userStore.user && (
                            <div className={styles.name + " me-3"}>
                                <UserIcon />
                                <Link to={"/account"}>{userStore.user.displayName}</Link>
                            </div>
                        )}
                        {!userStore.isLoggedIn && (
                            <Button
                                variant="dark"
                                style={{
                                    backgroundColor: "#ec407a",
                                    borderColor: "#ec407a",
                                }}
                                className={"me-2"}
                                onClick={() => {
                                    appStore.setShowSignup(true);
                                }}>
                                {userStore.authing ? (
                                    <Spinner size={"sm"} animation={"border"} />
                                ) : (
                                    <>
                                        <MdLogin /> Logga in
                                    </>
                                )}
                            </Button>
                        )}
                        <PlaylistToggle />
                    </Navbar>
                </div>
            </Navbar>
        </div>
    );
});
