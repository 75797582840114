import { makeAutoObservable } from "mobx";
import { createClient } from "@/core/helpers";
import { ApiClient } from "@/clients/ApiClient";

export class EpisodeStore {
    latest: Episode[] = [];
    loading = false;
    getting = false;
    page = 1;
    private client: ApiClient;
    constructor() {
        makeAutoObservable(this);
        this.client = createClient();
    }

    async loadLatest(lang = "sv", onlyToplist = 1) {
        this.loading = true;
        if (this.latest.length > 0) {
            return;
        }
        const response = await this.client.get<EpisodesResponse>(
            `/episodes/latest?lang=${lang}&onlyToplist=${onlyToplist}`
        );
        this.latest = response.episodes;
        this.loading = false;
    }

    async loadMore(lang = "sv", onlyToplist = 1) {
        this.page += 1;
        this.getting = true;
        const response = await this.client.get<EpisodesResponse>(
            `/episodes/latest?lang=${lang}&page=${this.page}&&onlyToplist=${onlyToplist}`
        );
        const old = this.latest;
        this.latest = [...old, ...response.episodes];
        this.getting = false;
    }
}
