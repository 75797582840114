declare global {
    interface Window {
        // tslint:disable-next-line: no-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: any[];
    }
}

interface EventProps {
    category: string;
    action: string;
    label?: string | null;
    value?: number | null;
}
export const trackEvent = ({ category, action, label = null, value = null }: EventProps) => {
    window.dataLayer = window.dataLayer || [];
    const payload: {
        [key: string]: string | number;
    } = {
        event: `${category}_${action}`,
    };
    if (label) {
        payload.label = label;
    }
    if (value) {
        payload.value = value;
    }
    window.dataLayer.push(payload);
};
